@import url("https://fonts.googleapis.com/css2?family=Montserrat&family=Titillium+Web:wght@300&display=swap");

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  margin: 0;
  font-family: "Montserrat", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
p {
  font-size: 1.6rem;
  text-align: center;
}

h1 {
  font-size: 6rem;
  text-align: center;
}
h2 {
  font-size: 2rem;
  text-align: center;
}

.temp {
  margin-top: 2rem;
}

.app {
  width: 100%;
  height: 1540px;
  background-color: rgba(0, 0, 0, 0.25);
  color: #ffffff;
}

.app:before {
  content: "";
  background: url("./assets/göteborg.jpeg") no-repeat center/cover;
  background-attachment: fixed;
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.app .search {
  text-align: center;
  padding: 1rem;
}

.app input {
  padding: 1rem 2rem;
  font-size: 1.2rem;
  border-radius: 25px;
  border: none;
  background: rgba(255, 255, 255, 0.1);
}

::placeholder {
  color: #f8f8f8;
}

.container {
  max-width: 700px;
  margin: auto;
  padding: 0 1rem;
  top: 8vh;
  position: relative;
  background-color: rgba(19, 90, 122, 0.5);
  padding: 2rem;
  border-radius: 12px;
}

.app .key-details {
  display: flex;
  justify-content: space-evenly;
  text-align: center;
  width: 100%;
  margin-top: 2rem;
  padding: 1rem;
}

.air {
  margin-top: 2rem;
  padding: 1.5rem 1rem;
}

.bold {
  font-weight: 700;
}

.unit {
  margin-top: -1.2rem;
  font-size: 1.2rem;
  margin-right: 5%;
  text-align: right;
}
